<template>
  <v-row no-gutters>
    <div class="mr-2">
      <defaultFieldTypes
        :fieldAttributes="result.fields['n_width']"
        field="n_width"
        :value="result.valueLine.n_width"
      ></defaultFieldTypes>
    </div>
    <defaultFieldTypes
      :fieldAttributes="result.fields['n_height']"
      field="n_height"
      :value="result.valueLine.n_height"
    ></defaultFieldTypes>
  </v-row>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  props: ["result"],
  components: { defaultFieldTypes },
};
</script>